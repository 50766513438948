.resize{
    resize: both;
    &-none{
        resize: none;
    }
    &-y{
        resize: vertical;
    }
    &-x{
        resize: horizontal;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &resize{
                resize: both;
                &-none{
                    resize: none;
                }
                &-y{
                    resize: vertical;
                }
                &-x{
                    resize: horizontal;
                }
            }
        }
    }
}
