.border- {
    &collapse {
        border-collapse: collapse;
    }
    &separate {
        border-collapse: separate;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &border- {
                &collapse {
                    border-collapse: collapse;
                }
                &separate {
                    border-collapse: separate;
                }
            }
        }
    }
}