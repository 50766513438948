@for $i from 0 to 17 {
    .outline-{
        &#{$i}{
            outline-width: #{$i}px;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            @for $i from 0 to 17 {
                &outline-{
                    &#{$i}{
                        outline-width: #{$i}px;
                    }
                }
            }
        }
    }
}
