.flex- {
    &column {
        flex-direction: column;
        &-reverse {
            flex-direction: column-reverse;
        }
    }
    &row {
        flex-direction: row;
        &-reverse {
            flex-direction: row-reverse;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &flex- {
                &column {
                    flex-direction: column;
                    &-reverse {
                        flex-direction: column-reverse;
                    }
                }
                &row {
                    flex-direction: row;
                    &-reverse {
                        flex-direction: row-reverse;
                    }
                }
            }
        }
    }
}