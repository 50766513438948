.justify-content- {
    &center {
        justify-content: center;
    }
    &start {
        justify-content: flex-start;
    }
    &end {
        justify-content: flex-end;
    }
    &around {
        justify-content: space-around;
    }
    &between {
        justify-content: space-between;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &justify-content- {
                &center {
                    justify-content: center;
                }
                &start {
                    justify-content: flex-start;
                }
                &end {
                    justify-content: flex-end;
                }
                &around {
                    justify-content: space-around;
                }
                &between {
                    justify-content: space-between;
                }
            }
        }
    }
}
