
.h- {
    &auto {
        height: auto;
    }
    &fit {
        height: fit-content;
    }
    &100 {
        height: 100%;
    }
    &screen {
        height: 100vh;
    }
    &middle {
        height: 50vh;
    }
}
.min-h- {
    &0 {
        min-height: 0;
    }
    &100 {
        min-height: 100%;
    }
    &middle {
        height: 50vh;
    }
    &screen {
        min-height: 100vh;
    }
}
.max-h- {
    &initial {
        max-height: initial;
    }
    &100 {
        max-height: 100%;
    }
    &middle {
        height: 50vh;
    }
    &screen {
        max-height: 100vh;
    }
}

@for $i from 0 to 65{
    .h-#{$i}{
        height: #{.25 * $i}rem;
    }
    .min-h-#{$i}{
        min-height: #{.25 * $i}rem;    
    }
    .max-h-#{$i}{
        max-height: #{.25 * $i}rem;
    }
}


@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &h- {
                &auto {
                    height: auto;
                }
                &fit {
                    height: fit-content;
                }
                &100 {
                    height: 100%;
                }
                &screen {
                    height: 100vh;
                }
                &middle {
                    height: 50vh;
                }
            }
            &min-h- {
                &0 {
                    min-height: 0;
                }
                &100 {
                    min-height: 100%;
                }
                &middle {
                    height: 50vh;
                }
                &screen {
                    min-height: 100vh;
                }
            }
            &max-h- {
                &initial {
                    max-height: initial;
                }
                &100 {
                    max-height: 100%;
                }
                &middle {
                    height: 50vh;
                }
                &screen {
                    max-height: 100vh;
                }
            }
            
            @for $i from 0 to 65{
                &h-#{$i}{
                    height: #{.25 * $i}rem;
                }
                &min-h-#{$i}{
                    min-height: #{.25 * $i}rem;    
                }
                &max-h-#{$i}{
                    max-height: #{.25 * $i}rem;
                }
            }
        }
    }
}