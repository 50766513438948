.tracking- {
    &pressed {
        letter-spacing: -0.03em;
    }
    &normal {
        letter-spacing: 0;
    }
    &soft {
        letter-spacing: .046875em;
    }
    &loose {
        letter-spacing: .098125em;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &tracking- {
                &pressed {
                    letter-spacing: -0.03em;
                }
                &normal {
                    letter-spacing: 0;
                }
                &soft {
                    letter-spacing: .046875em;
                }
                &loose {
                    letter-spacing: .078125em;
                }
            }
        }
    }
}