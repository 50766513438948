.form-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    &>[class*=col-],
    &>.col {
        padding-right: 5px;
        padding-left: 5px;
        position: relative;
        width: 100%;
    }
}

.form-group{
    margin-bottom: 16px;
}

.form-label {
    color: #292929;
    display: inline-block;
    margin-bottom: 6px;
    font-size: 14px;
}

.inner-form{
    position: relative;
}

.form-helper{
    position: absolute;
    opacity: 1;
    width: 35px;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.form-input {
    height: 36px;
    border: 1px solid #e2e5ed;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    box-shadow: inset 0px 1px 2px 0px rgba(102, 113, 123, 0.21);
    padding: 0 12px;
    font-size: 14px;
    display: flex;

    &::placeholder {
        color: #A4B5C6;
    }

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
    }

    &-helper{
        padding-right: 35px;
        transition: all .2s,
    }

    &-select{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIzMDguOTE4cHgiIGhlaWdodD0iNDMxLjQwMnB4IiB2aWV3Qm94PSIwIDAgMzA4LjkxOCA0MzEuNDAyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMDguOTE4IDQzMS40MDIiCgkgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIGZpbGw9IiM5NDk2OUIiIHBvaW50cz0iMCwxNzEuNDAxIDE1NC40NTksMCAzMDguOTE4LDE3MS40MDEgIi8+Cjxwb2x5Z29uIGZpbGw9IiM5NDk2OUIiIHBvaW50cz0iMzA4LjkxOCwyNjAgMTU0LjQ1OSw0MzEuNDAyIDAsMjYwICIvPgo8L3N2Zz4=");
        background-position: calc(100% - 7px) center;
        background-repeat: no-repeat;
        background-size: 7px;
        overflow: hidden;
        padding-right: 20px;
        cursor: pointer;

        &-angle{
            background-image: url('/assets/images/layout/helpers/angle_down.svg');
            background-size: 15px;
            overflow: hidden;
            padding-right: 30px;
            text-align-last: center;
        }
    }

    &-textarea{
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        height: auto;
    }
}

[class*='-mark']{
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;

    &::after{
        content: '';
        position: absolute;
        margin-top: 2px;
        height: 13px;
        top: 0;
        left: 0;
        background-color: #DEDEDE;
        border: 1px solid #B2B2B2;
        box-shadow: 0px 1px 1px 0px rgba(29, 36, 28, 0.1),inset 0px 2px 0px 0px rgba(255, 255, 255, 0.06);
        background-repeat: no-repeat;
        background-size: 0px;
        background-position: center;
    }
}

.checkbox-mark::after{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+');
    border-radius: 3px;
    width: 12.5px;
}

.form-input-checkbox:checked + .checkbox-mark::after {
    background-size: 7px;
    background-color: #4299e1;
}

.radio-mark::after{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0wLDI1NkMwLDExNC41LDExNC41LDAsMjU2LDBjMTQxLjQsMCwyNTYsMTE0LjUsMjU2LDI1NlMzOTcuNCw1MTIsMjU2LDUxMkMxMTQuNSw1MTIsMCwzOTcuNSwwLDI1NnoiLz4KPC9zdmc+');
    border-radius: 100%;
    width: 13px;
}

.form-input-radio:checked + .radio-mark::after {
    background-size: 5px;
    background-color: #4299e1;
}
