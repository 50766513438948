.align- {
    &items-{
        &center {
            align-items: center;
        }
        &start {
            align-items: flex-start;
        }
        &end {
            align-items: flex-end;
        }
        &stretch {
            align-items: stretch;
        }
        &baseline {
            align-items: baseline;
        }
    }
    &content- {
        &center {
            align-content: center;
        }
        &start {
            align-content: flex-start;
        }
        &end {
            align-content: flex-end;
        }
        &between {
            align-content: space-between;
        }
        &around {
            align-content: space-around;
        }
    }
    &self- {
        &auto {
            align-self: auto;
        }
        &center {
            align-self: center;
        }
        &start {
            align-self: flex-start;
        }
        &end {
            align-self: flex-end;
        }
        &stretch {
            align-self: stretch;
        }
        &baseline {
            align-self: baseline;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &align- {
                &items-{
                    &center {
                        align-items: center;
                    }
                    &start {
                        align-items: flex-start;
                    }
                    &end {
                        align-items: flex-end;
                    }
                    &stretch {
                        align-items: stretch;
                    }
                    &baseline {
                        align-items: baseline;
                    }
                }
                &content- {
                    &center {
                        align-content: center;
                    }
                    &start {
                        align-content: flex-start;
                    }
                    &end {
                        align-content: flex-end;
                    }
                    &between {
                        align-content: space-between;
                    }
                    &around {
                        align-content: space-around;
                    }
                }
                &self- {
                    &auto {
                        align-self: auto;
                    }
                    &center {
                        align-self: center;
                    }
                    &start {
                        align-self: flex-start;
                    }
                    &end {
                        align-self: flex-end;
                    }
                    &stretch {
                        align-self: stretch;
                    }
                    &baseline {
                        align-self: baseline;
                    }
                }
            }
        }
    }
}