.order- {
    &first {
        order: -99999999;
    }
    &last {
        order: 99999999;
    }
    @for $i from 0 to 11{
        &#{$i} {
            order: $i;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &order- {
                &first {
                    order: -99999999;
                }
                &last {
                    order: 99999999;
                }
                @for $i from 0 to 11{
                    &#{$i} {
                        order: $i;
                    }
                }
            }
        }
    }
}