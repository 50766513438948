.relative {
    position: relative;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.static {
    position: static;
}
.sticky {
    position: sticky;
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &relative {
                position: relative;
            }
            &fixed {
                position: fixed;
            }
            &absolute {
                position: absolute;
            }
            &static {
                position: static;
            }
            &sticky {
                position: sticky;
            }
        }
    }
}