.bullet- {
    &none {
        list-style: none;
    }
    &disc {
        list-style-type: disc;
    }
    &decimal {
        list-style-type: decimal;
    }
    &circle {
        list-style-type: circle;
    }
    &inside {
        list-style-position: inside;
    }
    &outside {
        list-style-position: outside;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &bullet- {
                &none {
                    list-style: none;
                }
                &disc {
                    list-style-type: disc;
                }
                &decimal {
                    list-style-type: decimal;
                }
                &circle {
                    list-style-type: circle;
                }
                &inside {
                    list-style-position: inside;
                }
                &outside {
                    list-style-position: outside;
                }
            }
        }
    }
}