.z-0{
    z-index: 0;
}

@for $i from 1 to 11{
    .z-#{$i}{
        z-index: $i;
    }
    .-z-#{$i}{
        z-index: -$i;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &z-0{
                z-index: 0;
            }
            @for $i from 1 to 11{
                &z-#{$i}{
                    z-index: $i;
                }
                &-z-#{$i}{
                    z-index: -$i;
                }
            }
        }
    }
}
