.top-0{
    top: 0;
}
.left-0{
    left: 0;
}
.right-0{
    right: 0;
}
.bottom-0{
    bottom: 0;
}
.top-auto{
    top: auto;
}
.left-auto{
    left: auto;
}
.right-auto{
    right: auto;
}
.bottom-auto{
    bottom: auto;
}
.inset-{
    &0{
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &auto{
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
    &y-0{
        top: 0;
        bottom: 0;
    }
    &x-{
        right: 0;
        left: 0;
    }
}


@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &top-0{
                top: 0;
            }
            &left-0{
                left: 0;
            }
            &right-0{
                right: 0;
            }
            &bottom-0{
                bottom: 0;
            }
            &top-auto{
                top: auto;
            }
            &left-auto{
                left: auto;
            }
            &right-auto{
                right: auto;
            }
            &bottom-auto{
                bottom: auto;
            }
            &inset-{
                &0{
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
                &auto{
                    top: auto;
                    right: auto;
                    bottom: auto;
                    left: auto;
                }
                &y-0{
                    top: 0;
                    bottom: 0;
                }
                &x-{
                    right: 0;
                    left: 0;
                }
            }            
        }
    }
}