.overflow- {
    &hidden {
        overflow: hidden;
    }
    &auto {
        overflow: auto;
    }
    &scroll {
        overflow: scroll;
    }
    &visible {
        overflow: visible;
    }
    &y- {
        &hidden {
            overflow-y: hidden;
        }
        &auto {
            overflow-y: auto;
        }
        &scroll {
            overflow-y: scroll;
        }
        &visible {
            overflow-y: visible;
        }
    }
    &x- {
        &hidden {
            overflow-x: hidden;
        }
        &auto {
            overflow-x: auto;
        }
        &scroll {
            overflow-x: scroll;
        }
        &visible {
            overflow-x: visible;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &overflow- {
                &hidden {
                    overflow: hidden;
                }
                &auto {
                    overflow: auto;
                }
                &scroll {
                    overflow: scroll;
                }
                &visible {
                    overflow: visible;
                }
                &y- {
                    &hidden {
                        overflow-y: hidden;
                    }
                    &auto {
                        overflow-y: auto;
                    }
                    &scroll {
                        overflow-y: scroll;
                    }
                    &visible {
                        overflow-y: visible;
                    }
                }
                &x- {
                    &hidden {
                        overflow-x: hidden;
                    }
                    &auto {
                        overflow-x: auto;
                    }
                    &scroll {
                        overflow-x: scroll;
                    }
                    &visible {
                        overflow-x: visible;
                    }
                }
            }
        }
    }
}