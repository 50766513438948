.flex- {
    &none {
        flex: none;
    }
    &auto {
        flex: auto;
    }
    &content {
        flex: content;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &flex- {
                &none {
                    flex: none;
                }
                &auto {
                    flex: auto;
                }
                &content {
                    flex: content;
                }
            }
        }
    }
}