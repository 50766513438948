.overlay{
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    display: none;
    padding: 32px 16px;
    min-height: 100vh;
    width: 100%;

    &.active{
        display: block;
    }
}

.close-modal{
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    top: 0;
    right: 0;
    font-size: 24px;
    color: #808080;
    z-index: 12;
}

.wrapper-lightbox{
    position: relative;
    margin: 0 auto;
    padding-top: 52px;
    width: 100%;
}

.lightbox{
    position: relative;
    background: #fff;
    height: auto;
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
}
