.burger {
    width: 45px;
    height: 45px;
    display: block;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    &:focus{
        border: thin dotted;
        box-shadow: 0 0 0 0;
        background-color: transparent;
    }
}

.burger-inner{
    position: relative;
}

.burger-inner-line{
    display: block;
    position: absolute;
    height: 3px;
    width: 29px;
    margin: 0 auto;
    background: #0f0f0f;
    position: absolute;

    &:nth-child(2){
        position: relative;
    }
    &:first-child{
        bottom: 6px;
    }
    &:last-child {
        top: 6px;
    }
}

.trigger-menu{
    .burger-inner-line{
        &:first-child{
            transition: bottom 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms cubic-bezier(0.23, 1, 0.32, 1);
        }
        &:last-child {
            transition: top 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    }

    &-toggled .burger-inner-line{
        &:nth-child(2){
            background: rgba(255, 255, 255, 0);
        }
        &:first-child {
            bottom: 0;
            transform: rotate(-45deg);
            transition: bottom 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1);
        }
        &:last-child {
            top: 0;
            transform: rotate(45deg);
            transition: top 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    }
}

.trigger-slide{
    .burger-inner,
    .burger-inner-line{
        transition: all 250ms;
    }

    &-toggled .burger-inner {
        transform: rotate(180deg);

        .burger-inner-line {
            width: 24px;
            &:first-child {
                width: 14px;
                transform: translateX(12.5px) translateY(1.85px) rotate(45deg);
            }

            &:last-child {
                width: 14px;
                transform: translateX(12.5px) translateY(-1.85px) rotate(-45deg);
            }
        }
    }
}
