.flex- {
    &nowrap {
        flex-wrap: nowrap;
    }
    &wrap {
        flex-wrap: wrap;
        &-reverse {
            flex-wrap: wrap-reverse;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &flex- {
                &nowrap {
                    flex-wrap: nowrap;
                }
                &wrap {
                    flex-wrap: wrap;
                    &-reverse {
                        flex-wrap: wrap-reverse;
                    }
                }
            }
        }
    }
}