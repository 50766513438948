.table- {
    &auto {
        table-layout: auto;
    }
    &fixed {
        table-layout: fixed;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &table- {
                &auto {
                    table-layout: auto;
                }
                &fixed {
                    table-layout: fixed;
                }
            }
        }
    }
}