.w- {
    &auto {
        width: auto;
    }
    &fit {
        width: fit-content;
    }
    &100 {
        width: 100%;
    }
    &screen {
        width: 100vw;
    }
}
.min-w- {
    &100 {
        min-width: 100%;
    }
    &middle {
        min-width: 50vh;
    }
    &screen {
        min-width: 100vh;
    }
}
.max-w- {
    &initial {
        max-width: initial;
    }
    &100 {
        max-width: 100%;
    }
    &middle {
        max-width: 50vh;
    }
    &screen {
        max-width: 100vh;
    }
}

@for $i from 0 to 65{
    .w-#{$i}{
        width: #{.25 * $i}rem;
    }
    .min-w-#{$i}{
        min-width: #{.25 * $i}rem;    
    }
    .max-w-#{$i}{
        max-width: #{.25 * $i}rem;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &w- {
                &auto {
                    width: auto;
                }
                &fit {
                    width: fit-content;
                }
                &100 {
                    width: 100%;
                }
                &screen {
                    width: 100vw;
                }
            }
            &min-w- {
                &100 {
                    min-width: 100%;
                }
                &middle {
                    min-width: 50vh;
                }
                &screen {
                    min-width: 100vh;
                }
            }
            &max-w- {
                &initial {
                    max-width: initial;
                }
                &100 {
                    max-width: 100%;
                }
                &middle {
                    max-width: 50vh;
                }
                &screen {
                    max-width: 100vh;
                }
            }
            
            @for $i from 0 to 65{
                &w-#{$i}{
                    width: #{.25 * $i}rem;
                }
                &min-w-#{$i}{
                    min-width: #{.25 * $i}rem;    
                }
                &max-w-#{$i}{
                    max-width: #{.25 * $i}rem;
                }
            }
        }
    }
}