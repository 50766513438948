.rounded-{
    &full{
        border-radius: 100%;
    }
    &t-full{
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
    }
    &r-full{
        border-top-right-radius: 100%;
        border-bottom-right-radius: 100%;
    }
    &b-full{
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
    }
    &l-full{
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;
    }
    &tr-full{
        border-top-right-radius: 100%;
    }
    &br-full{
        border-bottom-right-radius: 100%;
    }
    &bl-full{
        border-bottom-left-radius: 100%;
    }
    &tl-full{
        border-top-left-radius: 100%;
    }
    @for $i from 0 to 21{
        &#{2 * $i}{
            border-radius: #{2 * $i}px;
        }
        &t-#{2 * $i}{
            border-top-left-radius: #{2 * $i}px;
            border-top-right-radius: #{2 * $i}px;
        }
        &r-#{2 * $i}{
            border-top-right-radius: #{2 * $i}px;
            border-bottom-right-radius: #{2 * $i}px;
        }
        &b-#{2 * $i}{
            border-bottom-left-radius: #{2 * $i}px;
            border-bottom-right-radius: #{2 * $i}px;
        }
        &l-#{2 * $i}{
            border-top-left-radius: #{2 * $i}px;
            border-bottom-left-radius: #{2 * $i}px;
        }
        &tr-#{2 * $i}{
            border-top-right-radius: #{2 * $i}px;
        }
        &br-#{2 * $i}{
            border-bottom-right-radius: #{2 * $i}px;
        }
        &bl-#{2 * $i}{
            border-bottom-left-radius: #{2 * $i}px;
        }
        &tl-#{2 * $i}{
            border-top-left-radius: #{2 * $i}px;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &rounded-{
                &full{
                    border-radius: 100%;
                }
                &t-full{
                    border-top-left-radius: 100%;
                    border-top-right-radius: 100%;
                }
                &r-full{
                    border-top-right-radius: 100%;
                    border-bottom-right-radius: 100%;
                }
                &b-full{
                    border-bottom-left-radius: 100%;
                    border-bottom-right-radius: 100%;
                }
                &l-full{
                    border-top-left-radius: 100%;
                    border-bottom-left-radius: 100%;
                }
                &tr-full{
                    border-top-right-radius: 100%;
                }
                &br-full{
                    border-bottom-right-radius: 100%;
                }
                &bl-full{
                    border-bottom-left-radius: 100%;
                }
                &tl-full{
                    border-top-left-radius: 100%;
                }
                @for $i from 0 to 21{
                    &#{2 * $i}{
                        border-radius: #{2 * $i}px;
                    }
                    &t-#{2 * $i}{
                        border-top-left-radius: #{2 * $i}px;
                        border-top-right-radius: #{2 * $i}px;
                    }
                    &r-#{2 * $i}{
                        border-top-right-radius: #{2 * $i}px;
                        border-bottom-right-radius: #{2 * $i}px;
                    }
                    &b-#{2 * $i}{
                        border-bottom-left-radius: #{2 * $i}px;
                        border-bottom-right-radius: #{2 * $i}px;
                    }
                    &l-#{2 * $i}{
                        border-top-left-radius: #{2 * $i}px;
                        border-bottom-left-radius: #{2 * $i}px;
                    }
                    &tr-#{2 * $i}{
                        border-top-right-radius: #{2 * $i}px;
                    }
                    &br-#{2 * $i}{
                        border-bottom-right-radius: #{2 * $i}px;
                    }
                    &bl-#{2 * $i}{
                        border-bottom-left-radius: #{2 * $i}px;
                    }
                    &tl-#{2 * $i}{
                        border-top-left-radius: #{2 * $i}px;
                    }
                }
            }
        }
    }
}