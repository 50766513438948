.hidden {
    display: none;
}
.block {
    display: block;
}
.flex{
    display: flex;
}
.table{
    display: table;
    &-row{
        display: table-row;
    }
    &-cell{
        display: table-cell;
    }
    &-row-group {
        display: table-row-group;
    }
    &-header-group {
        display: table-header-group;
    }
    &-footer-group {
        display: table-footer-group;
    }
    &-column-group {
        display: table-column-group;
    }
    &-column {
        display: table-column;
    }
    &-caption {
        display: table-caption;
    }
}

.inline{
    display: inline;
    &-block{
        display: inline-block;
    }
    &-flex{
        display: inline-flex;
    }
    &-table{
        display: inline-table;
    }
}
@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &hidden {
                display: none;
            }
            &block {
                display: block;
            }
            &flex{
                display: flex;
            }
            &table{
                display: table;
                &-row{
                    display: table-row;
                }
                &-cell{
                    display: table-cell;
                }
                &-row-group {
                    display: table-row-group;
                }
                &-header-group {
                    display: table-header-group;
                }
                &-footer-group {
                    display: table-footer-group;
                }
                &-column-group {
                    display: table-column-group;
                }
                &-column {
                    display: table-column;
                }
                &-caption {
                    display: table-caption;
                }
            }

            &inline{
                display: inline;
                &-block{
                    display: inline-block;
                }
                &-flex{
                    display: inline-flex;
                }
                &-table{
                    display: inline-table;
                }
            }
        }
    }
}
