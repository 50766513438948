.bg-size-{
    &auto{
        background-size: auto;
    }
    &cover{
        background-size: cover;
    }
    &contain{
        background-size: contain;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &bg-size-{
                &auto{
                    background-size: auto;
                }
                &cover{
                    background-size: cover;
                }
                &contain{
                    background-size: contain;
                }
            }
        }
    }
}