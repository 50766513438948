.text-{
    &right{
        text-align: right;
    }
    &left{
        text-align: left;
    }
    &center{
        text-align: center;
    }
    &justify{
        text-align: justify;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &text-{
                &right{
                    text-align: right;
                }
                &left{
                    text-align: left;
                }
                &center{
                    text-align: center;
                }
                &justify{
                    text-align: justify;
                }
            }
        }
    }
}


