/*
    ** SETTING **

    ** Used with preprocessors and contain font.
    ** In this layer define the variables that can customize the template.
*/

@import 'settings/fonts';
@import 'settings/vars';

/*
    ** TOOLS **

    ** Globally used mixins and functions.
    ** This layer is only used if we use a preprocessor.
*/

@import 'tools/mixin_mq';

/*
    ** GENERICS **

    ** Reset and/or normalize styles.
*/

@import 'generics/normalize';
@import 'generics/resets';

/*
    ** GRYD SYSTEM **

    ** Class_based selectors which define undecorated design patterns(system_grid).
*/

@import 'grid_system/grid';

/*
    ** BASICS COMPONENTS **

    ** Specific UI basics_components.
    ** For example button, card, concrete_list, etc.
*/

@import 'basics_components/boxes';
// @import 'basics_components/buttons';
@import 'basics_components/burger_menu';
@import 'basics_components/forms';
@import 'basics_components/modal';
@import 'basics_components/backdrop';

/*
    ** UTILITIES **

    ** Utilities and helper classes with ability to override anything which goes before.
    ** Can inlcude animations
*/

@import 'utilities/align';
@import 'utilities/background/attachment';
@import 'utilities/background/position';
@import 'utilities/background/repeat';
@import 'utilities/background/size';
@import 'utilities/borders/border_width';
@import 'utilities/borders/border_style';
@import 'utilities/borders/rounded';
@import 'utilities/box_shadow';
@import 'utilities/cursor';
@import 'utilities/display';
@import 'utilities/flex/align';
@import 'utilities/flex/direction';
@import 'utilities/flex/flex';
@import 'utilities/flex/grow';
@import 'utilities/flex/justify';
@import 'utilities/flex/shrink';
@import 'utilities/flex/order';
@import 'utilities/flex/wrap';
@import 'utilities/opacity';
@import 'utilities/outline/outline_style';
@import 'utilities/outline/outline_width';
@import 'utilities/overflow';
@import 'utilities/placement';
@import 'utilities/position';
@import 'utilities/resize';
@import 'utilities/sizings/height';
@import 'utilities/sizings/width';
@import 'utilities/spacings/margin';
@import 'utilities/spacings/padding';
@import 'utilities/tables/border';
@import 'utilities/tables/layout';
@import 'utilities/typography/align';
@import 'utilities/typography/break';
@import 'utilities/typography/decoration';
@import 'utilities/typography/spaced';
@import 'utilities/typography/style';
@import 'utilities/typography/tracking';
@import 'utilities/typography/list_style';
@import 'utilities/typography/shadow';
@import 'utilities/typography/size';
@import 'utilities/typography/transform';
@import 'utilities/typography/weight';
@import 'utilities/visibility';
@import 'utilities/z_index';
