@for $i from 0 to 65 {
    .p-#{$i}{
        padding: #{.25 * $i}rem;
    }
    .py-#{$i}{
        padding-top: #{.25 * $i}rem;
        padding-bottom: #{.25 * $i}rem;
    }
    .px-#{$i}{
        padding-right: #{.25 * $i}rem;
        padding-left: #{.25 * $i}rem;
    }
    .pt-#{$i}{
        padding-top: #{.25 * $i}rem;
    }
    .pr-#{$i}{
        padding-right: #{.25 * $i}rem;
    }
    .pb-#{$i}{
        padding-bottom: #{.25 * $i}rem;
    }
    .pl-#{$i}{
        padding-left: #{.25 * $i}rem;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            @for $i from 0 to 65 {
                &p-#{$i}{
                    padding: #{.25 * $i}rem;
                }
                &py-#{$i}{
                    padding-top: #{.25 * $i}rem;
                    padding-bottom: #{.25 * $i}rem;
                }
                &px-#{$i}{
                    padding-right: #{.25 * $i}rem;
                    padding-left: #{.25 * $i}rem;
                }
                &pt-#{$i}{
                    padding-top: #{.25 * $i}rem;
                }
                &pr-#{$i}{
                    padding-right: #{.25 * $i}rem;
                }
                &pb-#{$i}{
                    padding-bottom: #{.25 * $i}rem;
                }
                &pl-#{$i}{
                    padding-left: #{.25 * $i}rem;
                }
            }
        }
    }
}