/*
    ** Media mixin from $breakpoints map
*/
@mixin media($breakpoint) { 
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }

    @else {
        @warn "No value could be retrieved from `#{$breakpoint}`. " + "Make sure it is defined in `$breakpoints` map.";
    }
}

/*
    ** Media-up mixin from $breakpoints map
    ** Use of media-up
    ** You can use these media mixin for you self scss files, the size of mediaquery is accord the values of $breackpoint map

            **Example
            .text{
                color: blue;
                @include media-up(desktop($breakpoint map value)){
                    color: plum;
                }
            }
*/
@mixin media-up($breakpoint) { 
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }

    @else {
        @warn "No value could be retrieved from `#{$breakpoint}`. " + "Make sure it is defined in `$breakpoints` map.";
    }
}