.outline-{
    &solid{
        outline-style: solid;
    }
    &dashed{
        outline-style: dashed;
    }
    &dotted{
        outline-style: dotted;
    }
    &double{
        outline-style: double;
    }
    &none{
        outline-style: none;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &outline-{
                &solid{
                    outline-style: solid;
                }
                &dashed{
                    outline-style: dashed;
                }
                &dotted{
                    outline-style: dotted;
                }
                &double{
                    outline-style: double;
                }
                &none{
                    outline-style: none;
                }
            }
        }
    }
}