.visible{
    visibility: visible;
}
.invisible{
    visibility: hidden;
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &visible{
                visibility: visible;
            }
            &invisible{
                visibility: hidden;
            }
        }
    }
}