.pull- {
    &right {
        margin-left: auto;
    }
    &left {
        margin-right: auto;
    }
    &center {
        margin: 0 auto;
    }
    &baselin {
        vertical-align: baseline;
    }
    &top {
        vertical-align: top;
    }
    &middle {
        vertical-align: middle;
    }
    &bottom {
        vertical-align: bottom;
    }
    &text- {
        &top {
            vertical-align: text-top;
        }
        &bottom {
            vertical-align: text-bottom;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &pull- {
                &right {
                    margin-left: auto;
                }
                &left {
                    margin-right: auto;
                }
                &center {
                    margin: 0 auto;
                }
                &baselin {
                    vertical-align: baseline;
                }
                &top {
                    vertical-align: top;
                }
                &middle {
                    vertical-align: middle;
                }
                &bottom {
                    vertical-align: bottom;
                }
                &text- {
                    &top {
                        vertical-align: text-top;
                    }
                    &bottom {
                        vertical-align: text-bottom;
                    }
                }
            }
        }
    }
}