.spacing-0{
    margin: 0;
    padding: 0;
}
.my-auto{
    margin-top: auto;
    margin-bottom: auto;
}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.ml-auto{
    margin-left: auto;
}

.mr-auto{
    margin-right: auto;
}

@for $i from 0 to 65 {
    .m-#{$i}{
        margin: #{.25 * $i}rem;
    }
    .-m-#{$i}{
        margin: #{.25 * $i}rem;
    }
    .my-#{$i}{
        margin-top: #{.25 * $i}rem;
        margin-bottom: #{.25 * $i}rem;
    }
    .-my-#{$i}{
        margin-top: -#{.25 * $i}rem;
        margin-bottom: -#{.25 * $i}rem;
    }
    .mx-#{$i}{
        margin-right: #{.25 * $i}rem;
        margin-left: #{.25 * $i}rem;
    }
    .-mx-#{$i}{
        margin-right: -#{.25 * $i}rem;
        margin-left: -#{.25 * $i}rem;
    }
    .mt-#{$i}{
        margin-top: #{.25 * $i}rem;
    }
    .-mt-#{$i}{
        margin-top: -#{.25 * $i}rem;
    }
    .mr-#{$i}{
        margin-right: #{.25 * $i}rem;
    }
    .-mr-#{$i}{
        margin-right: -#{.25 * $i}rem;
    }
    .mb-#{$i}{
        margin-bottom: #{.25 * $i}rem;
    }
    .-mb-#{$i}{
        margin-bottom: -#{.25 * $i}rem;
    }
    .ml-#{$i}{
        margin-left: #{.25 * $i}rem;
    }
    .-ml-#{$i}{
        margin-left: -#{.25 * $i}rem;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
           &spacing-0{
                margin: 0;
                padding: 0;
            }
           &my-auto{
                margin-top: auto;
                margin-bottom: auto;
            }
           &mx-auto{
                margin-left: auto;
                margin-right: auto;
            }

            &ml-auto{
                margin-left: auto;
            }

            &ml-auto{
                margin-right: auto;
            }

            @for $i from 0 to 65 {
               &m-#{$i}{
                    margin: #{.25 * $i}rem;
                }
               &-m-#{$i}{
                    margin: #{.25 * $i}rem;
                }
               &my-#{$i}{
                    margin-top: #{.25 * $i}rem;
                    margin-bottom: #{.25 * $i}rem;
                }
               &-my-#{$i}{
                    margin-top: -#{.25 * $i}rem;
                    margin-bottom: -#{.25 * $i}rem;
                }
               &mx-#{$i}{
                    margin-right: #{.25 * $i}rem;
                    margin-left: #{.25 * $i}rem;
                }
               &-mx-#{$i}{
                    margin-right: -#{.25 * $i}rem;
                    margin-left: -#{.25 * $i}rem;
                }
               &mt-#{$i}{
                    margin-top: #{.25 * $i}rem;
                }
               &-mt-#{$i}{
                    margin-top: -#{.25 * $i}rem;
                }
               &mr-#{$i}{
                    margin-right: #{.25 * $i}rem;
                }
               &-mr-#{$i}{
                    margin-right: -#{.25 * $i}rem;
                }
               &mb-#{$i}{
                    margin-bottom: #{.25 * $i}rem;
                }
               &-mb-#{$i}{
                    margin-bottom: -#{.25 * $i}rem;
                }
               &ml-#{$i}{
                    margin-left: #{.25 * $i}rem;
                }
               &-ml-#{$i}{
                    margin-left: -#{.25 * $i}rem;
                }
            }
        }
    }
}
