@for $i from 2 to 65 {
    .text-#{2 * $i}{
         font-size:  #{0.125 * $i}rem;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            @for $i from 2 to 65 {
                &text-#{2 * $i}{
                     font-size:  #{0.125 * $i}rem;
                }
            }
        }
    }
}
