/*
    ** All elements border-box
*/
*,
*:before,
*::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #d2d2d2;
    outline-width: 0;
    outline-style: solid;
    outline-color: #d2d2d2;
}


/*
    ** Root element font size
    ** No default margins and paddings
*/
html {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

/*
    **root elements display block
*/
html,
body {
    display: block;
}

/*
    ** Body main style
*/
body {
    line-height: 1.317101995;
    scroll-behavior: smooth;
}

/*
    ** Correct align graphics elements
*/
img,
embed,
svg,
audio,
canvas,
iframe,
video {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

/*
    ** Correct margins containers texts
*/

h1{
    margin-top: 0;
}

h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 0.67em;
}

p {
    margin-bottom: 1rem;
    margin-top: 0;
}

/*
    ** Resets input tex
*/
[type='email'],
[type='number'],
[type='submit'],
[type='text'],
[type='button'],
[type='reset'],
[type='search'],
textarea,
select {
    appearance: none;

    /*
        ** Delete outline
    */
    &:focus {
        color: #353536;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
    }
}

/*
    ** Style input text
*/
[type='email'],
[type='number'],
[type='submit'],
[type='text'],
[type='button'],
[type='reset'],
[type='search'],
textarea,
select {
    border: 1px solid #d2d2d2;
    background: #fff;
    padding: 0 12px;
    font-size: 14px;
    min-height: 24px;
}

/*
    ** Style button
*/
button{
    border: 1px solid #d2d2d2;
    background: #fff;
    min-height: 24px;

    &:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
    }
}

/*
    ** Padding text area
*/
textarea {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    resize: vertical;
}

/*
    ** Ever cursos pointer
*/
a,
button,
[type='submit'] {
    cursor: pointer;
}

/*
    ** a Text color
*/
a{
    color: inherit;
}

/*
    ** Style focus outline button and a elements
*/
button,
a{
    &:focus {
        outline: thin dotted;
    }
}

/*
    ** Style hr
*/
hr {
    margin: 1rem 0;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #D2D2D2;
    height: 0;
    display: block;
    width: 100%;
}

/*
    ** Correct spacing fieldset
*/
fieldset {
    border: 0;
    margin: 0;
}
