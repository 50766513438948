.bordev{
    box-shadow:0 0 0 1px #c553a3, 0px 0px 2px #c553a3,inset 0px 0px 2px #c553a3;
}

.border-collapse{
    border-collapse: collapse;
}

.border-{
    @for $i from 0 to 17{
        &#{$i}{
            border-width: #{$i}px;
        }
    }
    &y-{
        @for $i from 0 to 17{
            &#{$i}{
                border-top-width: #{$i}px;
                border-bottom-width: #{$i}px;
            }
        }
    }
    &x-{
        @for $i from 0 to 17{
            &#{$i}{
                border-top-width: #{$i}px;
                border-bottom-width: #{$i}px;
            }
        }
    }
    &t-{
        @for $i from 0 to 17{
            &#{$i}{
                border-top-width: #{$i}px
            }
        }
    }
    &r-{
        @for $i from 0 to 17{
            &#{$i}{
                border-right-width: #{$i}px
            }
        }
    }
    &b-{
        @for $i from 0 to 17{
            &#{$i}{
                border-bottom-width: #{$i}px
            }
        }
    }
    &l-{
        @for $i from 0 to 17{
            &#{$i}{
                border-left-width: #{$i}px
            }
        }
    }
}
@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &border-collapse{
                border-collapse: collapse;
            }
            &border-{
                @for $i from 0 to 17{
                    &#{$i}{
                        border-width: #{$i}px;
                    }
                }
                &y-{
                    @for $i from 0 to 17{
                        &#{$i}{
                            border-top-width: #{$i}px;
                            border-bottom-width: #{$i}px;
                        }
                    }
                }
                &x-{
                    @for $i from 0 to 17{
                        &#{$i}{
                            border-top-width: #{$i}px;
                            border-bottom-width: #{$i}px;
                        }
                    }
                }
                &t-{
                    @for $i from 0 to 17{
                        &#{$i}{
                            border-top-width: #{$i}px
                        }
                    }
                }
                &r-{
                    @for $i from 0 to 17{
                        &#{$i}{
                            border-right-width: #{$i}px
                        }
                    }
                }
                &b-{
                    @for $i from 0 to 17{
                        &#{$i}{
                            border-bottom-width: #{$i}px
                        }
                    }
                }
                &l-{
                    @for $i from 0 to 17{
                        &#{$i}{
                            border-left-width: #{$i}px
                        }
                    }
                }
            }
        }
    }
}
