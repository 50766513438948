.cursor- {
    &default {
        cursor: default;
    }
    &pointer {
        cursor: pointer;
    }
    &wait {
        cursor: wait;
    }
    &text {
        cursor: text;
    }
    &move {
        cursor: move;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &cursor- {
                &default {
                    cursor: default;
                }
                &pointer {
                    cursor: pointer;
                }
                &wait {
                    cursor: wait;
                }
                &text {
                    cursor: text;
                }
                &move {
                    cursor: move;
                }
            }
        }
    }
}