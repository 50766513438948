.flex- {
    &shrink {
        flex-shrink: 1;
        &-0 {
            flex-shrink: 0;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &flex- {
                &shrink {
                    flex-shrink: 1;
                    &-0 {
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}