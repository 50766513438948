.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
}
.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
}

[class^=col-],
[class*=\:col-],
.col {
    position: relative;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col- {
    &1 {
        flex: 0 0 calc(100% / 16);
        max-width: calc(100% / 16);
    }
    &2 {
        flex: 0 0 calc((100% / 16) * 2);
        max-width: calc((100% / 16) * 2);
    }
    &3 {
        flex: 0 0 calc((100% / 16) * 3);
        max-width: calc((100% / 16) * 3);
    }
    &4 {
        flex: 0 0 calc((100% / 16) * 4);
        max-width: calc((100% / 16) * 4);
    }
    &5 {
        flex: 0 0 calc((100% / 16) * 5);
        max-width: calc((100% / 16) * 5);
    }
    &6 {
        flex: 0 0 calc((100% / 16) * 6);
        max-width: calc((100% / 16) * 6);
    }
    &7 {
        flex: 0 0 calc((100% / 16) * 7);
        max-width: calc((100% / 16) * 7);
    }
    &8 {
        flex: 0 0 calc((100% / 16) * 8);
        max-width: calc((100% / 16) * 8);
    }
    &9 {
        flex: 0 0 calc((100% / 16) * 9);
        max-width: calc((100% / 16) * 9);
    }
    &10 {
        flex: 0 0 calc((100% / 16) * 10);
        max-width: calc((100% / 16) * 10);
    }
    &11 {
        flex: 0 0 calc((100% / 16) * 11);
        max-width: calc((100% / 16) * 11);
    }
    &12 {
        flex: 0 0 calc((100% / 16) * 12);
        max-width: calc((100% / 16) * 12);
    }

    &13 {
        flex: 0 0 calc((100% / 16) * 13);
        max-width: calc((100% / 16) * 13);
    }
    &14 {
        flex: 0 0 calc((100% / 16) * 14);
        max-width: calc((100% / 16) * 14);
    }
    &15 {
        flex: 0 0 calc((100% / 16) * 15);
        max-width: calc((100% / 16) * 15);
    }
    &16 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    &1\/3 {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
    }
    &2\/3 {
        flex: 0 0 calc((100% / 3) * 2);
        max-width: calc((100% / 3) * 2);
    }
    &1\/5 {
        flex: 0 0 calc(100% / 5);
        max-width: calc(100% / 5);
    }
    &2\/5 {
        flex: 0 0 calc((100% / 5) * 2);
        max-width: calc((100% / 5) * 2);
    }
    &3\/5 {
        flex: 0 0 calc((100% / 5) * 3);
        max-width: calc((100% / 5) * 3);
    }
    &4\/5 {
        flex: 0 0 calc((100% / 5) * 4);
        max-width: calc((100% / 5) * 4);
    }
    &1\/6 {
        flex: 0 0 calc(100% / 6);
        max-width: calc(100% / 6);
    }
    &2\/6 {
        flex: 0 0 calc((100% / 6) * 2);
        max-width: calc((100% / 6) * 2);
    }
    &3\/6 {
        flex: 0 0 calc((100% / 6) * 3);
        max-width: calc((100% / 6) * 3);
    }
    &4\/6 {
        flex: 0 0 calc((100% / 6) * 4);
        max-width: calc((100% / 6) * 4);
    }
    &5\/6 {
        flex: 0 0 calc((100% / 6) * 5);
        max-width: calc((100% / 6) * 5);
    }
    &1\/7 {
        flex: 0 0 calc(100% / 7);
        max-width: calc(100% / 7);
    }
    &2\/7 {
        flex: 0 0 calc((100% / 7) * 2);
        max-width: calc((100% / 7) * 2);
    }
    &3\/7 {
        flex: 0 0 calc((100% / 7) * 3);
        max-width: calc((100% / 7) * 3);
    }
    &4\/7 {
        flex: 0 0 calc((100% / 7) * 4);
        max-width: calc((100% / 7) * 4);
    }
    &5\/7 {
        flex: 0 0 calc((100% / 7) * 5);
        max-width: calc((100% / 7) * 5);
    }
    &6\/7 {
        flex: 0 0 calc((100% / 7) * 6);
        max-width: calc((100% / 7) * 6);
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &col {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
            &col- {
                &1 {
                    flex: 0 0 calc(100% / 16);
                    max-width: calc(100% / 16);
                }
                &2 {
                    flex: 0 0 calc((100% / 16) * 2);
                    max-width: calc((100% / 16) * 2);
                }
                &3 {
                    flex: 0 0 calc((100% / 16) * 3);
                    max-width: calc((100% / 16) * 3);
                }
                &4 {
                    flex: 0 0 calc((100% / 16) * 4);
                    max-width: calc((100% / 16) * 4);
                }
                &5 {
                    flex: 0 0 calc((100% / 16) * 5);
                    max-width: calc((100% / 16) * 5);
                }
                &6 {
                    flex: 0 0 calc((100% / 16) * 6);
                    max-width: calc((100% / 16) * 6);
                }
                &7 {
                    flex: 0 0 calc((100% / 16) * 7);
                    max-width: calc((100% / 16) * 7);
                }
                &8 {
                    flex: 0 0 calc((100% / 16) * 8);
                    max-width: calc((100% / 16) * 8);
                }
                &9 {
                    flex: 0 0 calc((100% / 16) * 9);
                    max-width: calc((100% / 16) * 9);
                }
                &10 {
                    flex: 0 0 calc((100% / 16) * 10);
                    max-width: calc((100% / 16) * 10);
                }
                &11 {
                    flex: 0 0 calc((100% / 16) * 11);
                    max-width: calc((100% / 16) * 11);
                }
                &12 {
                    flex: 0 0 calc((100% / 16) * 12);
                    max-width: calc((100% / 16) * 12);
                }

                &13 {
                    flex: 0 0 calc((100% / 16) * 13);
                    max-width: calc((100% / 16) * 13);
                }
                &14 {
                    flex: 0 0 calc((100% / 16) * 14);
                    max-width: calc((100% / 16) * 14);
                }
                &15 {
                    flex: 0 0 calc((100% / 16) * 15);
                    max-width: calc((100% / 16) * 15);
                }
                &16 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                &1\/3 {
                    flex: 0 0 calc(100% / 3);
                    max-width: calc(100% / 3);
                }
                &2\/3 {
                    flex: 0 0 calc((100% / 3) * 2);
                    max-width: calc((100% / 3) * 2);
                }
                &1\/5 {
                    flex: 0 0 calc(100% / 5);
                    max-width: calc(100% / 5);
                }
                &2\/5 {
                    flex: 0 0 calc((100% / 5) * 2);
                    max-width: calc((100% / 5) * 2);
                }
                &3\/5 {
                    flex: 0 0 calc((100% / 5) * 3);
                    max-width: calc((100% / 5) * 3);
                }
                &4\/5 {
                    flex: 0 0 calc((100% / 5) * 4);
                    max-width: calc((100% / 5) * 4);
                }
                &1\/6 {
                    flex: 0 0 calc(100% / 6);
                    max-width: calc(100% / 6);
                }
                &2\/6 {
                    flex: 0 0 calc((100% / 6) * 2);
                    max-width: calc((100% / 6) * 2);
                }
                &3\/6 {
                    flex: 0 0 calc((100% / 6) * 3);
                    max-width: calc((100% / 6) * 3);
                }
                &4\/6 {
                    flex: 0 0 calc((100% / 6) * 4);
                    max-width: calc((100% / 6) * 4);
                }
                &5\/6 {
                    flex: 0 0 calc((100% / 6) * 5);
                    max-width: calc((100% / 6) * 5);
                }
                &1\/7 {
                    flex: 0 0 calc(100% / 7);
                    max-width: calc(100% / 7);
                }
                &2\/7 {
                    flex: 0 0 calc((100% / 7) * 2);
                    max-width: calc((100% / 7) * 2);
                }
                &3\/7 {
                    flex: 0 0 calc((100% / 7) * 3);
                    max-width: calc((100% / 7) * 3);
                }
                &4\/7 {
                    flex: 0 0 calc((100% / 7) * 4);
                    max-width: calc((100% / 7) * 4);
                }
                &5\/7 {
                    flex: 0 0 calc((100% / 7) * 5);
                    max-width: calc((100% / 7) * 5);
                }
                &6\/7 {
                    flex: 0 0 calc((100% / 7) * 6);
                    max-width: calc((100% / 7) * 6);
                }
            }
        }
    }
}
