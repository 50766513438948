@for $i from 1 to 10{
    .weight-#{100 * $i}{
        font-weight: 100 * $i;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            @for $i from 1 to 10{
                &weight-#{100 * $i}{
                    font-weight: 100 * $i;
                }
            }
        }
    }
}