@for $i from 0 to 11 {
    .opacity-#{10 * $i}{
        opacity: .1 * $i;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            @for $i from 0 to 11 {
                &opacity-#{10 * $i}{
                    opacity: .1 * $i;
                }
            }
        }
    }
}