.break {
    &normal {
        word-break: normal;
        overflow-wrap: normal
    }
    &words {
        overflow-wrap: break-word;
    }
    &all {
        overflow-wrap: break-word;
    }
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.white-space-nowrap{
    white-space: nowrap;
}


@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &break {
                &normal {
                    word-break: normal;
                    overflow-wrap: normal
                }
                &words {
                    overflow-wrap: break-word;
                }
                &all {
                    overflow-wrap: break-word;
                }
            }

            &text-truncate {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
