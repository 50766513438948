.text-{
    &uppercase{
        text-transform: uppercase;
    }
    &capitalize{
        text-transform: capitalize;
    }
    &lowercase{
        text-transform: lowercase;
    }
    &transform-none{
        text-transform: none;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &text-{
                &uppercase{
                    text-transform: uppercase;
                }
                &capitalize{
                    text-transform: capitalize;
                }
                &lowercase{
                    text-transform: lowercase;
                }
                &transform-none{
                    text-transform: none;
                }
            }
        }
    }
}