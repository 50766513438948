.spaced- {
    &default {
        line-height: 1.317101995;
    }
    &pressed {
        line-height: 1;
    }
    &normal {
        line-height: normal;
    }
    &soft {
        line-height: 1.659910171;
    }
    &loose {
        line-height: 2;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &spaced- {
                &default {
                    line-height: 1.317101995;
                }
                &pressed {
                    line-height: 1;
                }
                &normal {
                    line-height: normal;
                }
                &soft {
                    line-height: 1.659910171;
                }
                &loose {
                    line-height: 2;
                }
            }
        }
    }
}