/*
    ** You can add your own font selected
*/

//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');


/*
    ** Import Font-Awsome from node_modelues
*/
// @import './node-modules/@fortawesome/fontawesome-free/scss/brands.scss';
// @import './node-modules/@fortawesome/fontawesome-free/scss/regular.scss';
// @import './node-modules/@fortawesome/fontawesome-free/scss/solid.scss';
// @import './node-modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';

