.bg-{
    &repeat{
        background-repeat: repeat;
        &-y{
            background-repeat: repeat-y;
        }
        &-x{
            background-repeat: repeat-x;
        }
        &-round{
            background-repeat: round;
        }
        &-space{
            background-repeat: space;
        }
    }
    &no-repeat{
        background-repeat: no-repeat;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &bg-{
                &repeat{
                    background-repeat: repeat;
                    &-y{
                        background-repeat: repeat-y;
                    }
                    &-x{
                        background-repeat: repeat-x;
                    }
                    &-round{
                        background-repeat: round;
                    }
                    &-space{
                        background-repeat: space;
                    }
                }
                &no-repeat{
                    background-repeat: no-repeat;
                }
            }
        }
    }
}