.text-{
    &decoration-none{
        text-decoration: none;
    }
    &underline{
        text-decoration: underline;
    }
    &line-over{
        text-decoration: line-through;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &text-{
                &decoration-none{
                    text-decoration: none;
                }
                &underline{
                    text-decoration: underline;
                }
                &line-over{
                    text-decoration: line-through;
                }
            }
        }
    }
}