.bg-position-{
    &center{
        background-position: center;
    }
    &top{
        background-position: top;
        &-right{
            background-position: right top;
        }
        &left{
            background-position: left top;
        }
    }
    &right{
        background-position: right;
    }
    &bottom{
        background-position: bottom;
        &-right{
            background-position: right bottom;
        }
        &-left{
            background-position: left bottom;
        }
    }
    &left{
        background-position: left;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &bg-position-{
                &center{
                    background-position: center;
                }
                &top{
                    background-position: top;
                    &-right{
                        background-position: right top;
                    }
                    &left{
                        background-position: left top;
                    }
                }
                &right{
                    background-position: right;
                }
                &bottom{
                    background-position: bottom;
                    &-right{
                        background-position: right bottom;
                    }
                    &-left{
                        background-position: left bottom;
                    }
                }
                &left{
                    background-position: left;
                }
            }
        }
    }
}