.flex- {
    &grow {
        flex-grow: 1;
        &-0 {
            flex-grow: 0;
        }
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &flex- {
                &grow {
                    flex-grow: 1;
                    &-0 {
                        flex-grow: 0;
                    }
                }
            }
        }
    }
}