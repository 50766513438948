/*
    ** Default colors **
*/
$white      :   #ffffff;
$black      :   #000000;
$grey       :   #9e9e9e;
$bluegrey   :   #607d8b;
$brown      :   #795548;
$red        :   #f44336;
$deeporange :   #ff5722;
$orange     :   #ff9800;
$amber      :   #ffc107;
$yellow     :   #ffeb3b;
$lime       :   #cddc39;
$lightgreen :   #8bc34a;
$green      :   #4caf50;
$teal       :   #009688;
$cyan       :   #00bcd4;
$lightblue  :   #03a9f4;
$blue       :   #2196f3;
$indigo     :   #3f51b5;
$deeppurple :   #673ab7;
$purple     :   #9c27b0;
$pink       :   #e91e63;

/*
    ** Border for development reasons
*/
$dev: 1px solid #c553a3;

/*
    ** MEDIA BREAKPOINTS **
    ** Define the mediaquery necessary for your project

    ** You can add, delete, or change the value and name of the key in the $ brackpoints map, also  you can overwrite the $breakpoints map for rewrite the keys


        ** EXAMPLE

        $breakpoints: (
            mobile      :   375px,
            tablet      :   768px,  
            laptop      :   1199px,
            desktop     :   1460px,
        );
*/

$breakpoints: (
    sm      :   480px,      // Default value
    md      :   768px,      // Default value
    lg      :   1024px,     // Default value
    xl      :   1199px,     // Default value
    xxl     :   1460px,     // Default value
);