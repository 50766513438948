.bg-{
    &fixed{
        background-attachment: fixed;
    }
    &local{
        background-attachment: local;
    }
    &scroll{
        background-attachment: scroll;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &bg-{
                &fixed{
                    background-attachment: fixed;
                }
                &local{
                    background-attachment: local;
                }
                &scroll{
                    background-attachment: scroll;
                }
            }
        }
    }
}