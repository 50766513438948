.text-{
    &italic {
        font-style: italic;
    }
    &no-italic {
        font-style: normal;
    }
}

@each $breakpoint, $size in $breakpoints {
    @include media-up($breakpoint){
        .#{$breakpoint}\:{
            &text-{
                &italic {
                    font-style: italic;
                }
                &no-italic {
                    font-style: normal;
                }
            }
        }
    }
}