.backdrop{
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    min-height: 100%;
    width: 100%;
    display: none;
}
